import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";

export interface SeoProps {
  title: string;
  description?: string;
  lang?: string;
  canonical?: string;
  contentType?: string;
  image?: string;
}

const Seo: React.FC<SeoProps> = ({
  description,
  lang,
  title,
  canonical,
  contentType,
  image,
}) => {
  const { site, file } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
        file(name: { eq: "banner" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const url = canonical || site.siteMetadata?.siteUrl;
  const ogImage = image || `${site.siteMetadata?.siteUrl}${getSrc(file)}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:type`,
          content: contentType,
        },
        {
          name: "og:url",
          content: url,
        },
        {
          name: "og:image",
          content: ogImage,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: "og:site_name",
          content: defaultTitle,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
      ]}
    >
      {canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  );
};

Seo.defaultProps = {
  lang: `en`,
  canonical: "",
  contentType: "website",
  image: "",
};

export default Seo;
